@import "shared";

.page {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.page-enter {
  opacity: 0;
  transform: translateY(-150px);
}

.page-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 800ms, transform 800ms;
}

.page-exit {
  opacity: 1;
  transform: translateY(0);
}

.page-exit-active {
  opacity: 0;
  transform: translateY(150px);
  transition: opacity 300ms, transform 800ms;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
}

.title {
  color: $darkTone;
  font-size: 3em;
  margin-top: 100px
}

.subText {
  font-size: 2em;
  color: $darkTone;
  margin: 0 5px;
}

.button {
  margin: 20px 10px;
  padding: 0 25px;
  text-decoration: none;
  background-color: $darkTone;
  font-size: 1.5em;
  color: $aquaTone;
  border-bottom-right-radius: 70px;
  border-bottom-left-radius: 70px;
  width: 100px;
}

.link {
  text-decoration: none;
  margin: 0 10px;
  color: $aquaTone;
  transition: opacity 300ms;
}

.link:hover {
  opacity: 0.75;
}

.project {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 10px;
  width: 100%;
  color: $darkTone;
  .link {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 0 10px;
    background-color: $darkTone;
  }
}

.projectHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
  font-size: 1.5em;
}

.verticalHR {
  transform: rotate(90deg);
  width: 30px
}

.details {
  margin: 0 10px 0 30px;
}

li {
  text-align: left;

}

.mobileDescription {
  font-weight: bold;
  margin: 0 10px 10px 20px;
}